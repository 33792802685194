<template>
  <div class="production_wrap">
    <div class = "box">
      <div class="about-title">交通设计研究院</div>
      <div class = "block-line"></div>
      <div class="about-container">
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;">大连市城市交通设计研究院有限公司，成立于1991年4月，主要从事市政道路、桥梁、排水、风景园林等专业的建设工程设计、技术咨询服务和测绘服务，从事市政工程设计的历史可追溯到二十世纪四十年代，是大连市城市建设投资集团有限公司的二级公司大连市国土空间规划设计有限公司下属的三级全资子公司，属于小型工程技术与设计服务行业企业，竞争类国有企业。</span>
        </p>
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;">公司目前具有工程设计市政行业（道路工程、桥梁工程）专业甲级、市政行业（排水工程）专业级、风景园林工程设计专项乙级资质，工程咨询单位乙级资信（市政公用工程），乙级测绘资质（工程测量、界线与不动产测绘）。</span>
        </p>
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;">公司业务覆盖的地域范围主要包括:大连市及所辖各区市县（市内四区、高新区、金普新区、旅顺口区、普兰店区长兴岛经济区、瓦房店市、庄河市）以及辽宁省内的营口、盘锦等地。服务对象包括大连市公用事业服务中心，各区住建局、服务中心及大型工程建设指挥部、房地产开发商、工厂、学校等企事业单位。在市内四区除政府项目外，我们与亿达、万科、新型等大型房地产开发公司建立了长期的战略合作关系。</span>
        </p>
      </div>
    </div>
    <CallMe />
    <MobileCallme />
  </div>
</template>

<script>
import CallMe from '@/components/callme/index'
import MobileCallme from '@/components/callme/MobileCallme.vue'
export default {
  data() {
    return {
    }
  },
  components: {
    CallMe,
    MobileCallme,
  },
}
</script>

<style lang="scss" scoped>
.production_wrap {
  padding-top: 200px;
  width: 100%;
}
</style>